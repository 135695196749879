<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33398 2.66659C1.33398 1.93021 1.93094 1.33325 2.66732 1.33325H13.334C14.0704 1.33325 14.6673 1.93021 14.6673 2.66659V13.3333C14.6673 14.0696 14.0704 14.6666 13.334 14.6666H2.66732C1.93094 14.6666 1.33398 14.0696 1.33398 13.3333V2.66659Z"
      fill="#D6372C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.16861 6.48452C8.51288 7.39282 9 8.00811 9.73249 8.45494C10.4394 8.36337 11.919 8.22054 12.4281 8.71864C12.8493 9.13616 12.776 10.3082 11.7066 10.3082C11.0876 10.3082 10.1573 10.0261 9.36258 9.59397C8.62375 9.71562 7.80319 9.99353 7.0158 10.2602C6.83129 10.3227 6.6486 10.3846 6.46922 10.4437C5.53894 12.0478 4.88702 12.7071 4.23143 12.7071C4.03 12.7071 3.6491 12.5935 3.444 12.3408C2.74446 11.4802 4.44752 10.3594 5.44006 9.85034L5.441 9.85025L5.44372 9.84851C5.44372 9.84851 5.44555 9.84668 5.44738 9.84668C5.89054 9.07023 6.51683 7.71511 6.94168 6.70427C6.63036 5.49931 6.46189 4.55439 6.64501 3.88415C6.84279 3.16264 8.22355 3.05643 8.39568 4.1332C8.58839 4.76129 8.34645 5.75448 8.18145 6.43177L8.16861 6.48452ZM7.60459 5.68243C7.42146 5.09644 7.42512 3.96473 7.53134 3.96473C7.83899 3.96473 7.80969 5.31618 7.60459 5.68243ZM6.50218 9.70756C6.90871 8.99703 7.26031 8.151 7.54232 7.41118C7.89392 8.04845 8.34075 8.55387 8.80588 8.90547C8.14488 9.03546 7.55072 9.28031 7.01076 9.50283C6.83574 9.57495 6.66624 9.6448 6.50218 9.70756ZM5.37413 10.5096C4.57937 11.7842 4.09592 12.0113 4.09592 11.982C4.27708 11.4916 4.9004 10.9346 5.23217 10.6382C5.28943 10.587 5.33801 10.5436 5.37413 10.5096ZM12.0362 9.49879C12.1864 9.39991 11.9446 9.06296 10.4687 9.16917C11.8274 9.74784 12.0362 9.49879 12.0362 9.49879Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
