<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 7C11.1895 7 10.5491 7.6877 10.6069 8.49619L10.9288 13.0025C10.9689 13.5646 11.4366 14 12 14C12.5635 14 13.0311 13.5646 13.0713 13.0025L13.3932 8.49619C13.4509 7.6877 12.8106 7 12 7ZM12 15C12.6904 15 13.25 15.5596 13.25 16.25C13.25 16.9404 12.6904 17.5 12 17.5C11.3097 17.5 10.75 16.9404 10.75 16.25C10.75 15.5596 11.3097 15 12 15Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
