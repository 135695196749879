<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM13.8906 18V6.625H13.6484L8.66406 8.35156V10.3516L11.2578 9.59375V18H13.8906Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
